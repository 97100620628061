#sidebar{
    width: 200px;
    height: 100vh;
}

#sidebar-container{
    color:black;
    background-color: #313a46;
    
    
    display:grid;
    grid-template-rows: max-content max-content 1fr;
    
    position:fixed;
    left:0;
    
}

#sidebar-container .sidebar-top h2{
    text-align: center;
    color:#8391a2;
    padding: 0.5rem;
    padding:1.5rem 0rem 0rem;
}

#sidebar-container .sidebar-middle{
    display: inline-grid;
    padding:2rem 0rem 3rem; 
    
    display: grid;
     grid-gap: 10px;
}

#sidebar-container .sidebar-middle a{
    padding: 1rem 0rem 1rem 1rem;
    display:grid;
    align-content: center;
    color:#8391a2;
    
    transition: all 0.2s ease;
    border-bottom: 1px solid gray;
   
}
#sidebar-container .sidebar-middle a:hover{
    color: #bccee4;

}

#sidebar-container .sidebar-middle a.is-active{
    color: white;

}

